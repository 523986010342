.popup_post_type2{
  background-color: red;
}


.popup_head{
  display:none;
}


