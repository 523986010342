:root {
  --aside: 1px 0px 12px 0px rgba(0, 0, 0, 0.2);
  --breakpoints-2xl: 1280px;
  --breakpoints-base: 0px;
  --breakpoints-lg: 1280px;
  --breakpoints-md: 1280px;
  --breakpoints-sm: 768px;
  --breakpoints-xl: 1280px;
  --FAB: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  --modal: 0px 0px 32px 0px rgba(0, 0, 0, 0.2);
  --pre-body-01-font-family: "Pretendard Variable", Helvetica;
  --pre-body-01-font-size: 18px;
  --pre-body-01-font-style: normal;
  --pre-body-01-font-weight: 600;
  --pre-body-01-letter-spacing: -0.18px;
  --pre-body-01-line-height: 170.00000476837158%;
  --pre-body-02-font-family: "Pretendard Variable", Helvetica;
  --pre-body-02-font-size: 18px;
  --pre-body-02-font-style: normal;
  --pre-body-02-font-weight: 400;
  --pre-body-02-letter-spacing: -0.18px;
  --pre-body-02-line-height: 170.00000476837158%;
  --pre-body-03-font-family: "Pretendard Variable", Helvetica;
  --pre-body-03-font-size: 16px;
  --pre-body-03-font-style: normal;
  --pre-body-03-font-weight: 600;
  --pre-body-03-letter-spacing: -0.16px;
  --pre-body-03-line-height: 170.00000476837158%;
  --pre-body-04-font-family: "Pretendard Variable", Helvetica;
  --pre-body-04-font-size: 16px;
  --pre-body-04-font-style: normal;
  --pre-body-04-font-weight: 400;
  --pre-body-04-letter-spacing: -0.16px;
  --pre-body-04-line-height: 170.00000476837158%;
  --pre-body-05-font-family: "Pretendard Variable", Helvetica;
  --pre-body-05-font-size: 14px;
  --pre-body-05-font-style: normal;
  --pre-body-05-font-weight: 600;
  --pre-body-05-letter-spacing: -0.14px;
  --pre-body-05-line-height: 170.00000476837158%;
  --pre-body-06-font-family: "Pretendard Variable", Helvetica;
  --pre-body-06-font-size: 14px;
  --pre-body-06-font-style: normal;
  --pre-body-06-font-weight: 400;
  --pre-body-06-letter-spacing: -0.14px;
  --pre-body-06-line-height: 170.00000476837158%;
  --pre-caption-01-font-family: "Pretendard Variable", Helvetica;
  --pre-caption-01-font-size: 12px;
  --pre-caption-01-font-style: normal;
  --pre-caption-01-font-weight: 600;
  --pre-caption-01-letter-spacing: -0.12px;
  --pre-caption-01-line-height: 170.00000476837158%;
  --pre-caption-02-font-family: "Pretendard Variable", Helvetica;
  --pre-caption-02-font-size: 12px;
  --pre-caption-02-font-style: normal;
  --pre-caption-02-font-weight: 400;
  --pre-caption-02-letter-spacing: -0.12px;
  --pre-caption-02-line-height: 170.00000476837158%;
  --pre-caption-03-font-family: "Pretendard Variable", Helvetica;
  --pre-caption-03-font-size: 10px;
  --pre-caption-03-font-style: normal;
  --pre-caption-03-font-weight: 600;
  --pre-caption-03-letter-spacing: -0.1px;
  --pre-caption-03-line-height: 170.00000476837158%;
  --pre-caption-04-font-family: "Pretendard Variable", Helvetica;
  --pre-caption-04-font-size: 10px;
  --pre-caption-04-font-style: normal;
  --pre-caption-04-font-weight: 400;
  --pre-caption-04-letter-spacing: -0.1px;
  --pre-caption-04-line-height: 170.00000476837158%;
  --pre-heading-01-font-family: "Pretendard Variable", Helvetica;
  --pre-heading-01-font-size: 40px;
  --pre-heading-01-font-style: normal;
  --pre-heading-01-font-weight: 700;
  --pre-heading-01-letter-spacing: -0.4px;
  --pre-heading-01-line-height: 150%;
  --pre-heading-02-font-family: "Pretendard Variable", Helvetica;
  --pre-heading-02-font-size: 36px;
  --pre-heading-02-font-style: normal;
  --pre-heading-02-font-weight: 700;
  --pre-heading-02-letter-spacing: -0.36px;
  --pre-heading-02-line-height: 150%;
  --pre-heading-03-font-family: "Pretendard Variable", Helvetica;
  --pre-heading-03-font-size: 22px;
  --pre-heading-03-font-style: normal;
  --pre-heading-03-font-weight: 600;
  --pre-heading-03-letter-spacing: -0.22px;
  --pre-heading-03-line-height: 150%;
  --pre-heading-04-font-family: "Pretendard Variable", Helvetica;
  --pre-heading-04-font-size: 20px;
  --pre-heading-04-font-style: normal;
  --pre-heading-04-font-weight: 600;
  --pre-heading-04-letter-spacing: -0.2px;
  --pre-heading-04-line-height: 170.00000476837158%;
  --primitives-2xgrid-0px: 0px;
  --primitives-2xgrid-0px-duplicate: 0px;
  --primitives-2xgrid-10px: 10px;
  --primitives-2xgrid-120px: 120px;
  --primitives-2xgrid-12px: 12px;
  --primitives-2xgrid-12px-duplicate: 12px;
  --primitives-2xgrid-14px: 14px;
  --primitives-2xgrid-160px: 160px;
  --primitives-2xgrid-16px: 16px;
  --primitives-2xgrid-16px-duplicate: 16px;
  --primitives-2xgrid-18px: 18px;
  --primitives-2xgrid-200px: 200px;
  --primitives-2xgrid-20px: 20px;
  --primitives-2xgrid-20px-duplicate: 20px;
  --primitives-2xgrid-240px: 240px;
  --primitives-2xgrid-24px: 24px;
  --primitives-2xgrid-280px: 280px;
  --primitives-2xgrid-28px: 28px;
  --primitives-2xgrid-2px: 2px;
  --primitives-2xgrid-2px-duplicate: 2px;
  --primitives-2xgrid-32px: 32px;
  --primitives-2xgrid-32px-duplicate: 32px;
  --primitives-2xgrid-36px: 36px;
  --primitives-2xgrid-40px: 40px;
  --primitives-2xgrid-48px: 48px;
  --primitives-2xgrid-4px: 4px;
  --primitives-2xgrid-4px-duplicate: 4px;
  --primitives-2xgrid-56px: 56px;
  --primitives-2xgrid-64px: 64px;
  --primitives-2xgrid-6px: 6px;
  --primitives-2xgrid-6px-duplicate: 6px;
  --primitives-2xgrid-72px: 72px;
  --primitives-2xgrid-80px: 80px;
  --primitives-2xgrid-8px: 8px;
  --primitives-2xgrid-8px-duplicate: 8px;
  --primitives-2xgrid-96px: 96px;
  --primitives-2xgrid-full: 9999px;
  --primitives-2xgrid-full-duplicate: 9999px;
  --primitives-color-blue-100: rgba(204, 224, 255, 1);
  --primitives-color-blue-200: rgba(132, 184, 255, 1);
  --primitives-color-blue-300: rgba(87, 157, 255, 1);
  --primitives-color-blue-400: rgba(56, 139, 255, 1);
  --primitives-color-blue-50: rgba(233, 242, 255, 1);
  --primitives-color-blue-500: rgba(29, 122, 252, 1);
  --primitives-color-blue-600: rgba(12, 102, 228, 1);
  --primitives-color-blue-700: rgba(0, 85, 204, 1);
  --primitives-color-blue-800: rgba(9, 50, 108, 1);
  --primitives-color-blue-900: rgba(28, 43, 65, 1);
  --primitives-color-common-black: rgba(0, 0, 0, 1);
  --primitives-color-common-white: rgba(255, 255, 255, 1);
  --primitives-color-common-white-duplicate: rgba(255, 255, 255, 1);
  --primitives-color-green-100: rgba(190, 244, 208, 1);
  --primitives-color-green-200: rgba(126, 226, 159, 1);
  --primitives-color-green-300: rgba(75, 206, 119, 1);
  --primitives-color-green-400: rgba(42, 187, 90, 1);
  --primitives-color-green-50: rgba(224, 255, 235, 1);
  --primitives-color-green-500: rgba(34, 160, 76, 1);
  --primitives-color-green-600: rgba(31, 132, 65, 1);
  --primitives-color-green-700: rgba(33, 110, 59, 1);
  --primitives-color-green-800: rgba(23, 79, 42, 1);
  --primitives-color-green-900: rgba(23, 23, 23, 1);
  --primitives-color-grey-100: rgba(226, 228, 231, 1);
  --primitives-color-grey-100-duplicate: rgba(226, 228, 231, 1);
  --primitives-color-grey-200: rgba(198, 203, 207, 1);
  --primitives-color-grey-300: rgba(169, 176, 183, 1);
  --primitives-color-grey-300-duplicate: rgba(169, 176, 183, 1);
  --primitives-color-grey-400: rgba(141, 150, 159, 1);
  --primitives-color-grey-400-duplicate: rgba(141, 150, 159, 1);
  --primitives-color-grey-50: rgba(240, 242, 243, 1);
  --primitives-color-grey-50-duplicate: rgba(240, 242, 243, 1);
  --primitives-color-grey-500: rgba(113, 125, 135, 1);
  --primitives-color-grey-500-duplicate: rgba(113, 125, 135, 1);
  --primitives-color-grey-600: rgba(90, 99, 108, 1);
  --primitives-color-grey-700: rgba(68, 75, 82, 1);
  --primitives-color-grey-700-duplicate: rgba(68, 75, 82, 1);
  --primitives-color-grey-800: rgba(45, 50, 54, 1);
  --primitives-color-grey-900: rgba(23, 25, 28, 1);
  --primitives-color-grey-900-duplicate: rgba(23, 25, 28, 1);
  --primitives-color-grey-transparent-100: rgba(0, 0, 0, 0.1);
  --primitives-color-grey-transparent-200: rgba(0, 0, 0, 0.2);
  --primitives-color-grey-transparent-300: rgba(0, 0, 0, 0.3);
  --primitives-color-grey-transparent-400: rgba(0, 0, 0, 0.4);
  --primitives-color-grey-transparent-50: rgba(0, 0, 0, 0.05);
  --primitives-color-grey-transparent-500: rgba(0, 0, 0, 0.5);
  --primitives-color-grey-transparent-600: rgba(0, 0, 0, 0.6);
  --primitives-color-grey-transparent-700: rgba(0, 0, 0, 0.7);
  --primitives-color-grey-transparent-800: rgba(0, 0, 0, 0.8);
  --primitives-color-grey-transparent-900: rgba(0, 0, 0, 0.9);
  --primitives-color-pink-100: rgba(253, 208, 213, 1);
  --primitives-color-pink-200: rgba(249, 149, 160, 1);
  --primitives-color-pink-300: rgba(247, 100, 118, 1);
  --primitives-color-pink-400: rgba(236, 80, 99, 1);
  --primitives-color-pink-50: rgba(255, 240, 241, 1);
  --primitives-color-pink-500: rgba(217, 68, 86, 1);
  --primitives-color-pink-600: rgba(206, 39, 59, 1);
  --primitives-color-pink-700: rgba(188, 21, 40, 1);
  --primitives-color-pink-800: rgba(101, 16, 26, 1);
  --primitives-color-pink-900: rgba(23, 23, 23, 1);
  --primitives-color-red-100: rgba(255, 213, 210, 1);
  --primitives-color-red-200: rgba(253, 152, 145, 1);
  --primitives-color-red-300: rgba(248, 113, 104, 1);
  --primitives-color-red-400: rgba(241, 91, 80, 1);
  --primitives-color-red-50: rgba(255, 241, 240, 1);
  --primitives-color-red-500: rgba(226, 72, 61, 1);
  --primitives-color-red-600: rgba(201, 55, 44, 1);
  --primitives-color-red-700: rgba(201, 55, 44, 1);
  --primitives-color-red-800: rgba(93, 31, 26, 1);
  --primitives-color-red-900: rgba(23, 23, 23, 1);
  --primitives-color-tblue-100: rgba(213, 208, 243, 1);
  --primitives-color-tblue-200: rgba(173, 163, 232, 1);
  --primitives-color-tblue-200-duplicate: rgba(173, 163, 232, 1);
  --primitives-color-tblue-300: rgba(131, 116, 221, 1);
  --primitives-color-tblue-400: rgba(91, 71, 209, 1);
  --primitives-color-tblue-50: rgba(234, 232, 249, 1);
  --primitives-color-tblue-50-duplicate: rgba(234, 232, 249, 1);
  --primitives-color-tblue-500: rgba(54, 23, 206, 1);
  --primitives-color-tblue-500-duplicate: rgba(54, 23, 206, 1);
  --primitives-color-tblue-600: rgba(40, 20, 158, 1);
  --primitives-color-tblue-600-duplicate: rgba(40, 20, 158, 1);
  --primitives-color-tblue-700: rgba(30, 15, 120, 1);
  --primitives-color-tblue-800: rgba(20, 10, 79, 1);
  --primitives-color-tblue-900: rgba(10, 5, 40, 1);
  --primitives-color-tsky-100: rgba(237, 238, 251, 1);
  --primitives-color-tsky-100-duplicate: rgba(237, 238, 251, 1);
  --primitives-color-tsky-200: rgba(220, 223, 247, 1);
  --primitives-color-tsky-200-duplicate: rgba(220, 223, 247, 1);
  --primitives-color-tsky-300: rgba(202, 206, 244, 1);
  --primitives-color-tsky-300-duplicate: rgba(202, 206, 244, 1);
  --primitives-color-tsky-400: rgba(185, 190, 240, 1);
  --primitives-color-tsky-50: rgba(246, 247, 253, 1);
  --primitives-color-tsky-50-duplicate: rgba(246, 247, 253, 1);
  --primitives-color-tsky-500: rgba(167, 174, 236, 1);
  --primitives-color-tsky-600: rgba(134, 139, 189, 1);
  --primitives-color-tsky-700: rgba(101, 105, 143, 1);
  --primitives-color-tsky-700-duplicate: rgba(101, 105, 143, 1);
  --primitives-color-tsky-800: rgba(67, 70, 94, 1);
  --primitives-color-tsky-800-duplicate: rgba(67, 70, 94, 1);
  --primitives-color-tsky-900: rgba(34, 35, 48, 1);
  --primitives-color-violet-100: rgba(232, 214, 255, 1);
  --primitives-color-violet-200: rgba(210, 175, 255, 1);
  --primitives-color-violet-300: rgba(187, 134, 255, 1);
  --primitives-color-violet-400: rgba(165, 94, 255, 1);
  --primitives-color-violet-50: rgba(243, 235, 255, 1);
  --primitives-color-violet-500: rgba(142, 55, 255, 1);
  --primitives-color-violet-600: rgba(114, 43, 204, 1);
  --primitives-color-violet-700: rgba(57, 22, 102, 1);
  --primitives-color-violet-800: rgba(57, 22, 102, 1);
  --primitives-color-violet-900: rgba(29, 11, 52, 1);
  --primitives-color-white-transparent-100: rgba(255, 255, 255, 0.1);
  --primitives-color-white-transparent-200: rgba(255, 255, 255, 0.2);
  --primitives-color-white-transparent-300: rgba(255, 255, 255, 0.3);
  --primitives-color-white-transparent-400: rgba(255, 255, 255, 0.4);
  --primitives-color-white-transparent-50: rgba(255, 255, 255, 0.05);
  --primitives-color-white-transparent-500: rgba(255, 255, 255, 0.5);
  --primitives-color-white-transparent-600: rgba(255, 255, 255, 0.6);
  --primitives-color-white-transparent-700: rgba(255, 255, 255, 0.7);
  --primitives-color-white-transparent-800: rgba(255, 255, 255, 0.8);
  --primitives-color-white-transparent-900: rgba(255, 255, 255, 0.9);
  --primitives-color-yellow-100: rgba(252, 241, 181, 1);
  --primitives-color-yellow-200: rgba(250, 225, 97, 1);
  --primitives-color-yellow-300: rgba(249, 212, 20, 1);
  --primitives-color-yellow-400: rgba(239, 184, 6, 1);
  --primitives-color-yellow-50: rgba(254, 247, 215, 1);
  --primitives-color-yellow-500: rgba(215, 162, 4, 1);
  --primitives-color-yellow-600: rgba(166, 125, 3, 1);
  --primitives-color-yellow-700: rgba(137, 102, 1, 1);
  --primitives-color-yellow-800: rgba(100, 76, 2, 1);
  --primitives-color-yellow-900: rgba(23, 23, 23, 1);
  --tokens-border-01: 0.5px;
  --tokens-border-02: 1px;
  --tokens-border-03: 2px;
  --tokens-border-04: 3px;
  --tokens-border-05: 4px;
  --tokens-color-background-brand: var(--primitives-color-tblue-50);
  --tokens-color-background-brand-active: var(--primitives-color-tblue-300);
  --tokens-color-background-brand-hover: var(--primitives-color-tblue-200);
  --tokens-color-background-brand-inverse: var(--primitives-color-tblue-500);
  --tokens-color-background-brand-inverse-active: var(--primitives-color-tblue-800);
  --tokens-color-background-brand-inverse-duplicate: var(--primitives-color-tblue-500-duplicate);
  --tokens-color-background-brand-inverse-hover: var(--primitives-color-tblue-700);
  --tokens-color-background-inverse: var(--primitives-color-grey-800);
  --tokens-color-background-inverse-active: var(--primitives-color-grey-400);
  --tokens-color-background-inverse-hover: var(--primitives-color-grey-600);
  --tokens-color-background-overlay: var(--primitives-color-grey-transparent-200);
  --tokens-color-background-overlay-subtle: var(--primitives-color-grey-transparent-500);
  --tokens-color-background-primary: var(--primitives-color-common-white);
  --tokens-color-background-primary-active: var(--primitives-color-grey-100);
  --tokens-color-background-primary-duplicate: var(--primitives-color-common-white-duplicate);
  --tokens-color-background-primary-hover: var(--primitives-color-grey-50);
  --tokens-color-background-primary-hover-duplicate: var(--primitives-color-grey-50-duplicate);
  --tokens-color-background-quarternary: var(--primitives-color-tsky-300);
  --tokens-color-background-quinary: var(--primitives-color-tsky-400);
  --tokens-color-background-secondary: var(--primitives-color-tsky-50);
  --tokens-color-background-secondary-active: var(--primitives-color-tsky-200);
  --tokens-color-background-secondary-duplicate: var(--primitives-color-tsky-50-duplicate);
  --tokens-color-background-secondary-hover: var(--primitives-color-tsky-100);
  --tokens-color-background-secondary-hover-duplicate: var(--primitives-color-tsky-100-duplicate);
  --tokens-color-background-senary: var(--primitives-color-tsky-500);
  --tokens-color-background-tertiary: var(--primitives-color-tsky-200);
  --tokens-color-background-tertiary-active: var(--primitives-color-tsky-400);
  --tokens-color-background-tertiary-duplicate: var(--primitives-color-tsky-200-duplicate);
  --tokens-color-background-tertiary-hover: var(--primitives-color-tsky-300);
  --tokens-color-badge-brand1: var(--primitives-color-tblue-50);
  --tokens-color-badge-brand1-duplicate: var(--primitives-color-tblue-50-duplicate);
  --tokens-color-badge-brand2: var(--primitives-color-tsky-700);
  --tokens-color-badge-brand2-duplicate: var(--primitives-color-tsky-700-duplicate);
  --tokens-color-badge-gre-subtle: var(--primitives-color-grey-50);
  --tokens-color-badge-grey: var(--primitives-color-grey-800);
  --tokens-color-badge-whitle: var(--primitives-color-common-white);
  --tokens-color-border-active: var(--primitives-color-tblue-500);
  --tokens-color-border-active-duplicate: var(--primitives-color-tblue-500-duplicate);
  --tokens-color-border-brand: var(--primitives-color-tblue-500);
  --tokens-color-border-brand-duplicate: var(--primitives-color-tblue-500-duplicate);
  --tokens-color-border-primary: var(--primitives-color-grey-300);
  --tokens-color-border-secondary: var(--primitives-color-grey-100);
  --tokens-color-border-secondary-duplicate: var(--primitives-color-grey-100-duplicate);
  --tokens-color-button-brand1: var(--primitives-color-tblue-500);
  --tokens-color-button-brand1-active: var(--primitives-color-tblue-700);
  --tokens-color-button-brand1-duplicate: var(--primitives-color-tblue-500-duplicate);
  --tokens-color-button-brand1-hover: var(--primitives-color-tblue-600);
  --tokens-color-button-brand1-hover-duplicate: var(--primitives-color-tblue-600-duplicate);
  --tokens-color-button-brand1-subtle: var(--primitives-color-tblue-50);
  --tokens-color-button-brand1-subtle-active: var(--primitives-color-tblue-200);
  --tokens-color-button-brand1-subtle-duplicate: var(--primitives-color-common-white-duplicate);
  --tokens-color-button-brand1-subtle-hover: var(--primitives-color-tblue-100);
  --tokens-color-button-brand1-subtle-hover-duplicate: var(--primitives-color-tblue-50-duplicate);
  --tokens-color-button-brand2: var(--primitives-color-tblue-400);
  --tokens-color-button-brand2-active: var(--primitives-color-tblue-600);
  --tokens-color-button-brand2-hover: var(--primitives-color-tblue-500);
  --tokens-color-button-brand2-subtle-active: var(--primitives-color-tsky-400);
  --tokens-color-button-brand2-subtle-hover: var(--primitives-color-tsky-300);
  --tokens-color-button-brand2-subtle-hover-duplicate: var(--primitives-color-tsky-300-duplicate);
  --tokens-color-button-brand2-subtle1: var(--primitives-color-tsky-100);
  --tokens-color-button-brand2-subtle1-duplicate: var(--primitives-color-tsky-100-duplicate);
  --tokens-color-button-brand2-subtle2: var(--primitives-color-tsky-200);
  --tokens-color-button-brand2-subtle2-duplicate: var(--primitives-color-tsky-200-duplicate);
  --tokens-color-button-disabled: var(--primitives-color-grey-200);
  --tokens-color-button-gray: var(--primitives-color-grey-300);
  --tokens-color-button-mute: var(--primitives-color-tsky-50);
  --tokens-color-button-mute-active: var(--primitives-color-tsky-200);
  --tokens-color-button-mute-duplicate: var(--primitives-color-tsky-50-duplicate);
  --tokens-color-button-mute-hover: var(--primitives-color-tsky-100);
  --tokens-color-button-mute-hover-duplicate: var(--primitives-color-tsky-100-duplicate);
  --tokens-color-icon-brand-subtle: var(--primitives-color-tblue-200);
  --tokens-color-icon-brand-subtle-duplicate: var(--primitives-color-tblue-200-duplicate);
  --tokens-color-icon-brand1: var(--primitives-color-tblue-500);
  --tokens-color-icon-brand1-duplicate: var(--primitives-color-tblue-500-duplicate);
  --tokens-color-icon-brand2: var(--primitives-color-tsky-800);
  --tokens-color-icon-disabled: var(--primitives-color-grey-300);
  --tokens-color-icon-disabled-on: var(--primitives-color-grey-400);
  --tokens-color-icon-primary: var(--primitives-color-grey-900);
  --tokens-color-icon-primary-inverse: var(--primitives-color-common-white);
  --tokens-color-icon-primary-inverse-duplicate: var(--primitives-color-common-white-duplicate);
  --tokens-color-icon-secondary: var(--primitives-color-grey-600);
  --tokens-color-icon-secondary-inverse: var(--primitives-color-grey-300);
  --tokens-color-icon-tertiary: var(--primitives-color-grey-400);
  --tokens-color-icon-tertiary-duplicate: var(--primitives-color-grey-400-duplicate);
  --tokens-color-support-error: var(--primitives-color-red-500);
  --tokens-color-support-error-accent: var(--primitives-color-red-600);
  --tokens-color-support-error-subtle: var(--primitives-color-red-50);
  --tokens-color-support-info: var(--primitives-color-blue-500);
  --tokens-color-support-shadow: var(--primitives-color-grey-transparent-200);
  --tokens-color-support-success: var(--primitives-color-green-400);
  --tokens-color-support-success-accent: var(--primitives-color-green-700);
  --tokens-color-support-success-subtle: var(--primitives-color-green-50);
  --tokens-color-support-warning: var(--primitives-color-yellow-300);
  --tokens-color-support-warning-accent: var(--primitives-color-yellow-700);
  --tokens-color-support-warning-subtle: var(--primitives-color-yellow-50);
  --tokens-color-text-brand1: var(--primitives-color-tblue-500);
  --tokens-color-text-brand1-duplicate: var(--primitives-color-tblue-500-duplicate);
  --tokens-color-text-brand2: var(--primitives-color-tsky-800);
  --tokens-color-text-brand2-duplicate: var(--primitives-color-tsky-800-duplicate);
  --tokens-color-text-disabled: var(--primitives-color-grey-300);
  --tokens-color-text-disabled-inverse: var(--primitives-color-grey-700);
  --tokens-color-text-primary: var(--primitives-color-grey-900);
  --tokens-color-text-primary-duplicate: var(--primitives-color-grey-900-duplicate);
  --tokens-color-text-primary-inverse: var(--primitives-color-common-white);
  --tokens-color-text-primary-inverse-duplicate: var(--primitives-color-common-white-duplicate);
  --tokens-color-text-quaternary: var(--primitives-color-grey-300);
  --tokens-color-text-quaternary-duplicate: var(--primitives-color-grey-300-duplicate);
  --tokens-color-text-secondary: var(--primitives-color-grey-700);
  --tokens-color-text-secondary-duplicate: var(--primitives-color-grey-700-duplicate);
  --tokens-color-text-secondary-inverse: var(--primitives-color-grey-300);
  --tokens-color-text-tertiary: var(--primitives-color-grey-500);
  --tokens-color-text-tertiary-duplicate: var(--primitives-color-grey-500-duplicate);
  --tokens-color-text-tertiary-inverse: var(--primitives-color-grey-600);
  --tokens-radius-01: var(--primitives-2xgrid-4px);
  --tokens-radius-01-duplicate: var(--primitives-2xgrid-4px-duplicate);
  --tokens-radius-02: var(--primitives-2xgrid-8px);
  --tokens-radius-02-duplicate: var(--primitives-2xgrid-8px-duplicate);
  --tokens-radius-03: var(--primitives-2xgrid-12px);
  --tokens-radius-04: var(--primitives-2xgrid-16px);
  --tokens-radius-05: var(--primitives-2xgrid-20px);
  --tokens-radius-06: var(--primitives-2xgrid-24px);
  --tokens-radius-07: var(--primitives-2xgrid-32px);
  --tokens-radius-08: var(--primitives-2xgrid-40px);
  --tokens-radius-09: var(--primitives-2xgrid-48px);
  --tokens-radius-10: var(--primitives-2xgrid-56px);
  --tokens-radius-11: var(--primitives-2xgrid-64px);
  --tokens-radius-12: var(--primitives-2xgrid-72px);
  --tokens-radius-13: var(--primitives-2xgrid-80px);
  --tokens-radius-full: var(--primitives-2xgrid-full);
  --tokens-radius-full-duplicate: var(--primitives-2xgrid-full-duplicate);
  --tokens-radius-none: var(--primitives-2xgrid-0px);
  --tokens-radius-none-duplicate: var(--primitives-2xgrid-0px-duplicate);
  --tokens-spacing-01: var(--primitives-2xgrid-2px);
  --tokens-spacing-01-duplicate: var(--primitives-2xgrid-2px-duplicate);
  --tokens-spacing-02: var(--primitives-2xgrid-4px);
  --tokens-spacing-02-duplicate: var(--primitives-2xgrid-4px-duplicate);
  --tokens-spacing-03: var(--primitives-2xgrid-6px);
  --tokens-spacing-03-duplicate: var(--primitives-2xgrid-6px-duplicate);
  --tokens-spacing-04: var(--primitives-2xgrid-8px);
  --tokens-spacing-04-duplicate: var(--primitives-2xgrid-8px-duplicate);
  --tokens-spacing-05: var(--primitives-2xgrid-10px);
  --tokens-spacing-06: var(--primitives-2xgrid-12px);
  --tokens-spacing-06-duplicate: var(--primitives-2xgrid-12px-duplicate);
  --tokens-spacing-07: var(--primitives-2xgrid-16px);
  --tokens-spacing-07-duplicate: var(--primitives-2xgrid-16px-duplicate);
  --tokens-spacing-08: var(--primitives-2xgrid-20px);
  --tokens-spacing-08-duplicate: var(--primitives-2xgrid-20px-duplicate);
  --tokens-spacing-09: var(--primitives-2xgrid-24px);
  --tokens-spacing-10: var(--primitives-2xgrid-28px);
  --tokens-spacing-11: var(--primitives-2xgrid-32px);
  --tokens-spacing-11-duplicate: var(--primitives-2xgrid-32px-duplicate);
  --tokens-spacing-12: var(--primitives-2xgrid-36px);
  --tokens-spacing-13: var(--primitives-2xgrid-40px);
  --tokens-spacing-14: var(--primitives-2xgrid-48px);
  --tokens-spacing-15: var(--primitives-2xgrid-56px);
  --tokens-spacing-16: var(--primitives-2xgrid-64px);
  --tokens-spacing-17: var(--primitives-2xgrid-80px);
  --tokens-spacing-18: var(--primitives-2xgrid-96px);
  --tokens-spacing-19: var(--primitives-2xgrid-120px);
  --tokens-spacing-20: var(--primitives-2xgrid-160px);
  --tokens-spacing-21: var(--primitives-2xgrid-200px);
  --tokens-spacing-22: var(--primitives-2xgrid-240px);
  --tokens-spacing-23: var(--primitives-2xgrid-280px);
  --tokens-spacing-none: var(--primitives-2xgrid-0px);
  --tokens-spacing-none-duplicate: var(--primitives-2xgrid-0px-duplicate);
}
