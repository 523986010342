/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* @font-face {
  font-family: "NotoSansKR-Bold";
  font-weight: normal;
  src: url("./Assets/NotoSansKR-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansKR-Medium";
  font-weight: normal;
  src: url("./Assets/NotoSansKR-Medium.ttf") format("truetype");
} */

@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard Variable';
  src: url('./Assets/Fonts/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Assets/Fonts/Inter-Medium.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "SCDream5";
  font-weight: normal;
  src: url("./Assets/Fonts/SCDream5.otf") format("opentype");
}

@font-face {
  font-family: "SCDream7";
  font-weight: normal;
  src: url("./Assets/Fonts/SCDream7.otf") format("opentype");
}

@font-face {
  font-family: "GmarketSansBold";
  font-weight: normal;
  src: url("./Assets/Fonts/GmarketSansBold.otf") format("opentype");
}

@font-face {
  font-family: "강원교육모두";
  font-weight: normal;
  src: url("./Assets/Fonts/GangwonEdu_OTFBoldA.woff") format("woff");
}

@font-face {
  font-family: "Jalnan2TTF";
  font-weight: normal;
  src: url("./Assets/Fonts/Jalnan2TTF.ttf") format("truetype");
}

@font-face {
  font-family: "NEXONLv1GothicBold";
  font-weight: normal;
  src: url("./Assets/Fonts/NEXONLv1GothicBold.ttf") format("truetype");
}

@font-face {
  font-family: "BinggraeII";
  font-weight: normal;
  src: url("./Assets/Fonts/BinggraeII.ttf") format("truetype");
}
@font-face {
  font-family: "VITRO_INSPIRE_OTF";
  font-weight: normal;
  src: url("./Assets/Fonts/VITRO_INSPIRE_OTF.otf") format("opentype");
}
@font-face {
  font-family: "INKLIPQUID";
  font-weight: normal;
  src: url("./Assets/Fonts/THEFACESHOP_INKLIPQUID.ttf") format("truetype");
}




@font-face{
  src: url("./Assets/Fonts/BlackHanSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-family: "BlackHanSans"
}
@font-face{
  src: url("./Assets/Fonts/Cafe24Ohsquare-v2.0.ttf") format("truetype");
  font-weight: normal;
  font-family: "Cafe24Ohsquare"
}
@font-face{
  src: url("./Assets/Fonts/땅스부대찌개_Medium.ttf") format("truetype");
  font-weight: normal;
  font-family: "TTTtangsbudaejjigaeB"
}
@font-face{
  src: url("./Assets/Fonts/ONE_Mobile_Title.ttf") format("truetype");
  font-weight: normal;
  font-family: "ONE-Mobile-Title"
}
@font-face{
  src: url("./Assets/Fonts/Paperlogy-6SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-family: "Paperlogy-6SemiBold"
}
@font-face{
  src: url("./Assets/Fonts/Paperlogy-7Bold.ttf") format("truetype");
  font-weight: normal;
  font-family: "Paperlogy-7Bold"
}
@font-face{
  src: url("./Assets/Fonts/SB_어그로_B.ttf") format("truetype");
  font-weight: normal;
  font-family: "SBAggroB"
}
@font-face{
  src: url("./Assets/Fonts/SB_어그로_M.ttf") format("truetype");
  font-weight: normal;
  font-family: "SBAggroM"
}
@font-face{
  src: url("./Assets/Fonts/Tenada.ttf") format("truetype");
  font-weight: normal;
  font-family: "Tenada"
}
@font-face{
  src: url("./Assets/Fonts/Giants-Bold.ttf") format("truetype");
  font-weight: normal;
  font-family: "Giants-Bold"
}
@font-face{
  src: url("./Assets/Fonts/Giants-Regular.ttf") format("truetype");
  font-weight: normal;
  font-family: "Giants-Regular"
}
@font-face{
  src: url("./Assets/Fonts/전남교육또박체_TTF_ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-family: "JNE-Ttobak-TTF-Extrabold"
}
@font-face{
  src: url("./Assets/Fonts/전남교육바른체_TTF_Bold.ttf") format("truetype");
  font-weight: normal;
  font-family: "JNE-Bareun-TTF-Bold"
}
@font-face{
  src: url("./Assets/Fonts/SANGJU_Gotgam.ttf") format("truetype");
  font-weight: normal;
  font-family: "SANJUGotgam"
}
@font-face{
  src: url("./Assets/Fonts/SANGJU_Gyeongcheon_Island.ttf") format("truetype");
  font-weight: normal;
  font-family: "SANGJUGyeongcheonIsland"
}
@font-face{
  src: url("./Assets/Fonts/Korail_Round_Gothic_Bold.ttf") format("truetype");
  font-weight: normal;
  font-family: "KorailRoundGothicBold"
}
@font-face{
  src: url("./Assets/Fonts/KORAIL.ttf") format("truetype");
  font-weight: normal;
  font-family: "Korail"
}
@font-face{
  src: url("./Assets/Fonts/korail_Condensed.ttf") format("truetype");
  font-weight: normal;
  font-family: "KORAILCondensed-Regular"
}
@font-face{
  src: url("./Assets/Fonts/Bobaesum_Jindo.ttf") format("truetype");
  font-weight: normal;
  font-family: "BobaesumJindoTTF"
}


body{ 
  width: 100vw;
  height: 100vh;
  background-color: white;
}
@media (max-width:900px){
  body{    
    width: 100%;
    height: 100%;
  }
}


:root{
  --2xgrid-full: 9999px;
  --2xgrid-full-rem: 624.9375rem;
  --2xgrid-280px: 280px;
  --2xgrid-280px-rem: 17.5rem;
  --2xgrid-240px: 240px;
  --2xgrid-240px-rem: 15rem;
  --2xgrid-200px: 200px;
  --2xgrid-200px-rem: 12.5rem;
  --2xgrid-160px: 160px;
  --2xgrid-160px-rem: 10rem;
  --2xgrid-120px: 120px;
  --2xgrid-120px-rem: 7.5rem;
  --2xgrid-96px: 96px;
  --2xgrid-96px-rem: 6rem;
  --2xgrid-72px: 72px;
  --2xgrid-72px-rem: 4.5rem;
  --2xgrid-80px: 80px;
  --2xgrid-80px-rem: 5rem;
  --2xgrid-64px: 64px;
  --2xgrid-64px-rem: 4rem;
  --2xgrid-56px: 56px;
  --2xgrid-56px-rem: 3.5rem;
  --2xgrid-48px: 48px;
  --2xgrid-48px-rem: 3rem;
  --2xgrid-40px: 40px;
  --2xgrid-40px-rem: 2.5rem;
  --2xgrid-36px: 36px;
  --2xgrid-36px-rem: 2.25rem;
  --2xgrid-32px: 32px;
  --2xgrid-32px-rem: 2rem;
  --2xgrid-28px: 28px;
  --2xgrid-28px-rem: 1.75rem;
  --2xgrid-24px: 24px;
  --2xgrid-24px-rem: 1.5rem;
  --2xgrid-20px: 20px;
  --2xgrid-20px-rem: 1.25rem;
  --2xgrid-18px: 18px;
  --2xgrid-18px-rem: 1.125rem;
  --2xgrid-16px: 16px;
  --2xgrid-16px-rem: 1rem;
  --2xgrid-14px: 14px;
  --2xgrid-14px-rem: 0.875rem;
  --2xgrid-12px: 12px;
  --2xgrid-12px-rem: 0.75rem;
  --2xgrid-10px: 10px;
  --2xgrid-10px-rem: 0.625rem;
  --2xgrid-8px: 8px;
  --2xgrid-8px-rem: 0.5rem;
  --2xgrid-6px: 6px;
  --2xgrid-6px-rem: 0.375rem;
  --2xgrid-4px: 4px;
  --2xgrid-4px-rem: 0.25rem;
  --2xgrid-2px: 2px;
  --2xgrid-2px-rem: 0.125rem;
  --2xgrid-0px: 0px;
  --2xgrid-0px-rem: 0rem;
  --color-common-white: #ffffff;
  --color-common-black: #000000;
  --color-grey-50: #f0f2f3;
  --color-grey-100: #e2e4e7;
  --color-grey-200: #c6cbcf;
  --color-grey-300: #a9b0b7;
  --color-grey-400: #8d969f;
  --color-grey-500: #717d87;
  --color-grey-600: #5a636c;
  --color-grey-700: #444b52;
  --color-grey-800: #2d3236;
  --color-grey-900: #17191c;
  --color-green-50: #e0ffeb;
  --color-green-100: #bef4d0;
  --color-green-200: #7ee29f;
  --color-green-300: #4bce77;
  --color-green-400: #2abb5a;
  --color-green-500: #22a04c;
  --color-green-600: #1f8441;
  --color-green-700: #216e3b;
  --color-green-800: #174f2a;
  --color-green-900: #171717;
  --color-yellow-50: #fef7d7;
  --color-yellow-100: #fcf1b5;
  --color-yellow-200: #fae161;
  --color-yellow-300: #f9d414;
  --color-yellow-400: #efb806;
  --color-yellow-500: #d7a204;
  --color-yellow-600: #a67d03;
  --color-yellow-700: #896601;
  --color-yellow-800: #644c02;
  --color-yellow-900: #171717;
  --color-blue-50: #e9f2ff;
  --color-blue-100: #cce0ff;
  --color-blue-200: #84b8ff;
  --color-blue-300: #579dff;
  --color-blue-400: #388bff;
  --color-blue-500: #1d7afc;
  --color-blue-600: #0c66e4;
  --color-blue-700: #0055cc;
  --color-blue-800: #09326c;
  --color-blue-900: #1c2b41;
  --color-red-50: #fff1f0;
  --color-red-100: #ffd5d2;
  --color-red-200: #fd9891;
  --color-red-300: #f87168;
  --color-red-400: #f15b50;
  --color-red-500: #e2483d;
  --color-red-600: #c9372c;
  --color-red-700: #c9372c;
  --color-red-800: #5d1f1a;
  --color-red-900: #171717;
  --color-pink-50: #fff0f1;
  --color-pink-100: #fdd0d5;
  --color-pink-200: #f995a0;
  --color-pink-300: #f76476;
  --color-pink-400: #ec5063;
  --color-pink-500: #d94456;
  --color-pink-600: #ce273b;
  --color-pink-700: #bc1528;
  --color-pink-800: #65101a;
  --color-pink-900: #171717;
  --color-violet-50: #f3ebff;
  --color-violet-100: #e8d6ff;
  --color-violet-200: #d2afff;
  --color-violet-300: #bb86ff;
  --color-violet-400: #a55eff;
  --color-violet-500: #8e37ff;
  --color-violet-600: #722bcc;
  --color-violet-700: #391666;
  --color-violet-800: #391666;
  --color-violet-900: #1d0b34;
  --color-tblue-50: #eae8f9;
  --color-tblue-100: #d5d0f3;
  --color-tblue-200: #ada3e8;
  --color-tblue-300: #8374dd;
  --color-tblue-400: #5b47d1;
  --color-tblue-500: #331ac6;
  --color-tblue-600: #28149e;
  --color-tblue-700: #1e0f78;
  --color-tblue-800: #140a4f;
  --color-tblue-900: #0a0528;
  --color-white-transparent-50: rgba(255 255 255 / 0.05);
  --color-white-transparent-100: rgba(255 255 255 / 0.1);
  --color-white-transparent-200: rgba(255 255 255 / 0.2);
  --color-white-transparent-300: rgba(255 255 255 / 0.3);
  --color-white-transparent-400: rgba(255 255 255 / 0.4);
  --color-white-transparent-500: rgba(255 255 255 / 0.5);
  --color-white-transparent-600: rgba(255 255 255 / 0.6);
  --color-white-transparent-700: rgba(255 255 255 / 0.7);
  --color-white-transparent-800: rgba(255 255 255 / 0.8);
  --color-white-transparent-900: rgba(255 255 255 / 0.9);
  --color-grey-transparent-50: rgba(0 0 0 / 0.05);
  --color-grey-transparent-100: rgba(0 0 0 / 0.1);
  --color-grey-transparent-200: rgba(0 0 0 / 0.2);
  --color-grey-transparent-300: rgba(0 0 0 / 0.3);
  --color-grey-transparent-400: rgba(0 0 0 / 0.4);
  --color-grey-transparent-500: rgba(0 0 0 / 0.5);
  --color-grey-transparent-600: rgba(0 0 0 / 0.6);
  --color-grey-transparent-700: rgba(0 0 0 / 0.7);
  --color-grey-transparent-800: rgba(0 0 0 / 0.8);
  --color-grey-transparent-900: rgba(0 0 0 / 0.9);
  --color-tsky-50: #f6f7fd;
  --color-tsky-100: #edeefb;
  --color-tsky-200: #dcdff7;
  --color-tsky-300: #cacef4;
  --color-tsky-400: #b9bef0;
  --color-tsky-500: #a7aeec;
  --color-tsky-600: #868bbd;
  --color-tsky-700: #65698f;
  --color-tsky-800: #43465e;
  --color-tsky-900: #222330;

    /* TOKENS */
    --color-text-brand1: var(--color-tblue-500);
    --color-text-brand2: var(--color-tsky-800);
    --color-text-primary: var(--color-grey-900);
    --color-text-secondary: var(--color-grey-700);
    --color-text-tertiary: var(--color-grey-500);
    --color-text-quaternary: var(--color-grey-300);
    --color-text-primary-inverse: var(--color-common-white);
    --color-text-secondary-inverse: var(--color-grey-300);
    --color-text-tertiary-inverse: var(--color-grey-600);
    --color-text-disabled: var(--color-grey-300);
    --color-text-disabled-inverse: var(--color-grey-700);

    --color-badge-brand1: var(--color-tblue-50);
    --color-badge-brand2: var(--color-tsky-700);
    --color-badge-grey: var(--color-grey-800);
    --color-badge-whitle: var(--color-common-white);
    --color-badge-gre-subtle: var(--color-grey-50);

    --color-border-primary: var(--color-grey-300);
    --color-border-secondary: var(--color-grey-100);
    --color-border-active: var(--color-tblue-500);
    --color-border-brand: var(--color-tblue-500);

    --color-support-info: var(--color-blue-500);
    --color-support-success: var(--color-green-400);
    --color-support-warning: var(--color-yellow-300);
    --color-support-error: var(--color-red-500);
    --color-support-shadow: var(--color-grey-transparent-200);
    --color-support-warning-subtle: var(--color-yellow-50);
    --color-support-success-accent: var(--color-green-700);
    --color-support-error-accent: var(--color-red-600);
    --color-support-success-subtle: var(--color-green-50);
    --color-support-error-subtle: var(--color-red-50);
    --color-support-warning-accent: var(--color-yellow-700);

    --radius-none: var(--2xgrid-0px);
    --radius-01: var(--2xgrid-4px);
    --radius-02: var(--2xgrid-8px);
    --radius-03: var(--2xgrid-12px);
    --radius-04: var(--2xgrid-16px);
    --radius-05: var(--2xgrid-20px);
    --radius-06: var(--2xgrid-24px);
    --radius-07: var(--2xgrid-32px);
    --radius-08: var(--2xgrid-40px);
    --radius-09: var(--2xgrid-48px);
    --radius-10: var(--2xgrid-56px);
    --radius-11: var(--2xgrid-64px);
    --radius-12: var(--2xgrid-72px);
    --radius-13: var(--2xgrid-80px);
    --radius-full: var(--2xgrid-full);
    
    --border-01: 0.5px;
    --border-01-rem: 0.0313rem;
    --border-02: 1px;
    --border-02-rem: 0.0625rem;
    --border-03: 2px;
    --border-03-rem: 0.125rem;
    --border-04: 3px;
    --border-04-rem: 0.1875rem;
    --border-05: 4px;
    --border-05-rem: 0.25rem;
    
    --spacing-none: var(--2xgrid-0px);
    --spacing-01: var(--2xgrid-2px);
    --spacing-02: var(--2xgrid-4px);
    --spacing-03: var(--2xgrid-6px);
    --spacing-04: var(--2xgrid-8px);
    --spacing-05: var(--2xgrid-10px);
    --spacing-06: var(--2xgrid-12px);
    --spacing-07: var(--2xgrid-16px);
    --spacing-08: var(--2xgrid-20px);
    --spacing-09: var(--2xgrid-24px);
    --spacing-10: var(--2xgrid-28px);
    --spacing-11: var(--2xgrid-32px);
    --spacing-12: var(--2xgrid-36px);
    --spacing-13: var(--2xgrid-40px);
    --spacing-14: var(--2xgrid-48px);
    --spacing-15: var(--2xgrid-56px);
    --spacing-16: var(--2xgrid-64px);
    --spacing-17: var(--2xgrid-80px);
    --spacing-18: var(--2xgrid-96px);
    --spacing-19: var(--2xgrid-120px);
    --spacing-20: var(--2xgrid-160px);
    --spacing-21: var(--2xgrid-200px);
    --spacing-22: var(--2xgrid-240px);
    --spacing-23: var(--2xgrid-280px);

    --color-icon-primary: var(--color-grey-900);
    --color-icon-secondary: var(--color-grey-600);
    --color-icon-tertiary: var(--color-grey-400);
    --color-icon-primary-inverse: var(--color-common-white);
    --color-icon-secondary-inverse: var(--color-grey-300);
    --color-icon-disabled-on: var(--color-grey-400);
    --color-icon-brand-subtle: var(--color-tblue-200);
    --color-icon-disabled: var(--color-grey-300);
    --color-icon-brand1: var(--color-tblue-500);
    --color-icon-brand2: var(--color-tsky-800);
    
    --color-background-brand: var(--color-tblue-50);
    --color-background-primary: var(--color-common-white);
    --color-background-secondary: var(--color-tsky-50);
    --color-background-tertiary: var(--color-tsky-200);
    --color-background-quarternary: var(--color-tsky-300);
    --color-background-quinary: var(--color-tsky-400);

    --color-background-brand-hover: var(--color-tblue-200);
    --color-background-primary-hover: var(--color-grey-50);
    --color-background-secondary-hover: var(--color-tsky-100);
    --color-background-tertiary-hover: var(--color-tsky-300);
    --color-background-inverse-hover: var(--color-grey-600);

    --color-background-brand-active: var(--color-tblue-300);
    --color-background-inverse-active: var(--color-grey-400);
    --color-background-primary-active: var(--color-grey-100);
    --color-background-secondary-active: var(--color-tsky-200);
    --color-background-tertiary-active: var(--color-tsky-400);

    --color-background-overlay: var(--color-grey-transparent-200);
    --color-background-inverse: var(--color-grey-800);
    --color-background-brand-inverse-active: var(--color-tblue-800);
    --color-background-brand-inverse: var(--color-tblue-500);
    --color-background-brand-inverse-hover: var(--color-tblue-700);
    --color-background-overlay-subtle: var(--color-grey-transparent-500);
    --color-background-senary: var(--color-tsky-500);
    
    --color-button-brand1-subtle-active: var(--color-tblue-200);
    --color-button-brand1-hover: var(--color-tblue-600);
    --color-button-brand2-subtle-hover: var(--color-tsky-300);
    --color-button-disabled: var(--color-grey-200);
    --color-button-brand1: var(--color-tblue-500);
    --color-button-brand2-subtle-active: var(--color-tsky-400);
    --color-button-brand1-subtle: var(--color-tblue-50);
    --color-button-brand1-subtle-hover: var(--color-tblue-100);
    --color-button-brand2-subtle1: var(--color-tsky-100);
    --color-button-brand1-active: var(--color-tblue-700);
    --color-button-brand2: var(--color-tblue-400);
    --color-button-brand2-hover: var(--color-tblue-500);
    --color-button-brand2-active: var(--color-tblue-600);
    --color-button-mute-hover: var(--color-tsky-100);
    --color-button-mute-active: var(--color-tsky-200);
    --color-button-gray: var(--color-grey-300);
    --color-button-mute: var(--color-tsky-50);
    --color-button-brand2-subtle2: var(--color-tsky-200);
  
    /* PRIMITIVES */
  
    /* BREAKPOINTS */
    --base: 0px;
    --base-rem: 0rem;
    --sm: 768px;
    --sm-rem: 48rem;
    --md: 1280px;
    --md-rem: 80rem;
    --lg: 1280px;
    --lg-rem: 80rem;
    --xl: 1280px;
    --xl-rem: 80rem;
    --2xl: 1280px;
    --2xl-rem: 80rem;
  
    /* COLORS */
    --waikawa-gray-50: #f2f7fb;
    --waikawa-gray-100: #e7f0f8;
    --waikawa-gray-200: #d3e2f2;
    --waikawa-gray-300: #b9cfe8;
    --waikawa-gray-400: #9cb6dd;
    --waikawa-gray-500: #839dd1;
    --waikawa-gray-600: #6a7fc1;
    --waikawa-gray-700: #6374ae;
    --waikawa-gray-800: #4a5989;
    --waikawa-gray-900: #414e6e;
    --waikawa-gray-950: #262c40;

  /* SPACING */
  /* --spacing-none: 0px;
  --spacing-01: 2px;
  --spacing-02: 4px;
  --spacing-03: 8px;
  --spacing-04: 12px;
  --spacing-05: 16px;
  --spacing-06: 20px;
  --spacing-07: 24px;
  --spacing-08: 28px;
  --spacing-09: 32px;
  --spacing-10: 36px;
  --spacing-11: 40px;
  --spacing-12: 48px;
  --spacing-13: 56px;
  --spacing-14: 64px;
  --spacing-15: 80px;
  --spacing-16: 96px;
  --spacing-17: 120px;
  --spacing-18: 160px;
  --spacing-19: 200px;
  --spacing-20: 240px;
  --spacing-21: 280px; */


  /* TYPE */
  --pre-heading-01-family: "Pretendard Variable";
  --pre-heading-01-size: 40px;
  --pre-heading-01-size-rem: 2.5rem;
  --pre-heading-01-weight: 700;
  --pre-heading-01-style: normal;
  --pre-heading-01-line-height: 150%;
  --pre-heading-02-family: "Pretendard Variable";
  --pre-heading-02-size: 36px;
  --pre-heading-02-size-rem: 2.25rem;
  --pre-heading-02-weight: 700;
  --pre-heading-02-style: normal;
  --pre-heading-02-line-height: 150%;
  --pre-heading-03-family: "Pretendard Variable";
  --pre-heading-03-size: 22px;
  --pre-heading-03-size-rem: 1.375rem;
  --pre-heading-03-weight: 600;
  --pre-heading-03-style: normal;
  --pre-heading-03-line-height: 150%;
  --pre-heading-04-family: "Pretendard Variable";
  --pre-heading-04-size: 20px;
  --pre-heading-04-size-rem: 1.25rem;
  --pre-heading-04-weight: 600;
  --pre-heading-04-style: normal;
  --pre-heading-04-line-height: 170%;
  --pre-body-01-family: "Pretendard Variable";
  --pre-body-01-size: 18px;
  --pre-body-01-size-rem: 1.125rem;
  --pre-body-01-weight: 600;
  --pre-body-01-style: normal;
  --pre-body-01-line-height: 170%;
  --pre-body-02-family: "Pretendard Variable";
  --pre-body-02-size: 18px;
  --pre-body-02-size-rem: 1.125rem;
  --pre-body-02-weight: 400;
  --pre-body-02-style: normal;
  --pre-body-02-line-height: 170%;
  --pre-body-03-family: "Pretendard Variable";
  --pre-body-03-size: 16px;
  --pre-body-03-size-rem: 1rem;
  --pre-body-03-weight: 600;
  --pre-body-03-style: normal;
  --pre-body-03-line-height: 170%;
  --pre-body-04-family: "Pretendard Variable";
  --pre-body-04-size: 16px;
  --pre-body-04-size-rem: 1rem;
  --pre-body-04-weight: 400;
  --pre-body-04-style: normal;
  --pre-body-04-line-height: 170%;
  --pre-body-05-family: "Pretendard Variable";
  --pre-body-05-size: 14px;
  --pre-body-05-size-rem: 0.875rem;
  --pre-body-05-weight: 600;
  --pre-body-05-style: normal;
  --pre-body-05-line-height: 170%;
  --pre-body-06-family: "Pretendard Variable";
  --pre-body-06-size: 14px;
  --pre-body-06-size-rem: 0.875rem;
  --pre-body-06-weight: 400;
  --pre-body-06-style: normal;
  --pre-body-06-line-height: 170%;
  --pre-caption-01-family: "Pretendard Variable";
  --pre-caption-01-size: 12px;
  --pre-caption-01-size-rem: 0.75rem;
  --pre-caption-01-weight: 600;
  --pre-caption-01-style: normal;
  --pre-caption-01-line-height: 170%;
  --pre-caption-02-family: "Pretendard Variable";
  --pre-caption-02-size: 12px;
  --pre-caption-02-size-rem: 0.75rem;
  --pre-caption-02-weight: 400;
  --pre-caption-02-style: normal;
  --pre-caption-02-line-height: 170%;
  --pre-caption-03-family: "Pretendard Variable";
  --pre-caption-03-size: 10px;
  --pre-caption-03-size-rem: 0.625rem;
  --pre-caption-03-weight: 600;
  --pre-caption-03-style: normal;
  --pre-caption-03-line-height: 170%;
  --pre-caption-04-family: "Pretendard Variable";
  --pre-caption-04-size: 10px;
  --pre-caption-04-size-rem: 0.625rem;
  --pre-caption-04-weight: 400;
  --pre-caption-04-style: normal;
  --pre-caption-04-line-height: 170%;

  /* SHADOWS */
  --aside: 1px 0 12px 0 rgba(0 0 0 / 0.2);
  --modal: 0 0 32px 0 rgba(0 0 0 / 0.2);
  --fab: 0 0 8px 2px rgba(0 0 0 / 0.2);

  /* GRIDS */
  --ppt-columns: 12;
  --ppt-gutter: 20px;
  --ppt-gutter-rem: 1.25rem;
  --pc-columns: 12;
  --pc-gutter: 20px;
  --pc-gutter-rem: 1.25rem;
}


::-webkit-scrollbar{  
  width:14px;
}
::-webkit-scrollbar-horizontal{
  border:none;
}
::-webkit-scrollbar-thumb{
  outline: none;
  border-radius: 16px;
  border: 3px solid transparent;
  box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.2) inset;

  cursor:pointer;
}
::-webkit-scrollbar-track{
  box-shadow: none;
  border: none;
  background-color: transparent;  
}

::-webkit-scrollbar-corner{  
  display: none; 
}

textarea {
  border: 1px solid #ccc; /* 기본 border 제거 */
  outline: none; /* 기본 outline 제거 */
  resize: both; /* resizer 사용 가능하게 설정 */
  overflow: auto; /* 스크롤바가 필요한 경우에만 보이도록 설정 */
}

textarea::-webkit-resizer {
  background-color: transparent; /* 배경색을 투명하게 설정 */
  border: none; /* 기본 border 제거 */
  width: 46px; /* resizer 크기 설정 */
  height: 46px; /* resizer 크기 설정 */
}