.page-view{
  width:100%;
  height:100vh;
  overflow: auto;
  /* display: grid; */
  /* grid-template-columns: 0.5fr 0.5fr; */
  display: flex;
  flex-direction: row;
}

@media (max-width:900px){
  .page-view{
    width: 100%;
    /* height: 100%; */
    display: grid;
    grid-template-rows: 0.5fr 0.5fr;
  }
}