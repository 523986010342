.page-view{
  width:100%;
  height:100%;
  /* display: grid; */
  /* grid-template-columns: 0.5fr 0.5fr; */
  display: flex;
  flex-direction: row;
}

@media (max-width:900px){
  .page-view{
    width: 100%;
    /* height: 100%; */
    display: grid;
    /* grid-template-rows: 0.5fr 0.5fr; */
  }
}

.input-area-content .textarea-requirement {
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #C2C2C2;
  padding:10px;
  width:100%;
  height:77px;
  resize: none;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
}