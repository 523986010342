.video-trimmer{
  width: 100%;
  height: 500px;
  box-sizing: border-box;
}

.video-trimmer .trimmer-control{
  padding: 10px 15px 10px 15px;
  display:flex;
  justify-content: flex-end;
}

.video-trimmer .trimmer-area{
  background-color: #484848;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding:10px; */
  box-sizing: border-box;
}


.video-trimmer .trimmer-area .trimmer-preview{
  width: 100%;
  height: calc(100% - 77px);
  padding:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #484848;
  position: relative
}

.video-trimmer .trimmer-area .trimmer-preview video{
  height:100%
}

.video-trimmer .trimmer-area .video-timeline-area{
  background-color: rgb(16, 16, 15);
  width: 100%;
  height: 77px;
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}
.video-trimmer .trimmer-area .video-timeline-area .preview-timeline-thumbnails{
  width:calc(100% - 32px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}
.video-trimmer .trimmer-area .video-timeline-area .preview-timeline-thumbnails .preview-thumbnail{
  width:16.667%;
  border:2px solid #6194C4;
  box-sizing: border-box;
  position: relative;
}

.video-trimmer .trimmer-area .trimmer-preview .video-screen{
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0);
  position: absolute;
}
.video-trimmer .trimmer-area .trimmer-preview .video-screen:hover{
  background-color: rgba(0,0,0,0.1);
  transition: 0.2s;
}

.video-trimmer .trimmer-area .trimmer-preview .video-screen.paused{
  background-color: rgba(0,0,0,0.3);
  transition: 0.2s;
}

.play-button{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0);
  z-index: 1;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0;
}

.play-button.paused{
  opacity: 1;
  transition: 0.2s;
}


.video-trimmer .trimmer-area .video-timeline-area .preview-timeline-segment{
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0px 16px 0px 16px;  
  display: flex;
  box-sizing: border-box;
}

.video-trimmer .trimmer-area .video-timeline-area .preview-timeline-segment .scrubber{
  border:1px solid red;
  width:1px;
  height:100%;
  z-index: 10;
  box-sizing: border-box;
  position: relative;
}

.video-trimmer .trimmer-area .video-timeline-area .preview-timeline-segment .segment{
  position:absolute;
  width:16px;
  height:100%;
  border:1px solid black;
  border-radius: 8px;
  background-color: #6194C4;
  box-sizing: border-box;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-trimmer .trimmer-area .video-timeline-area .preview-timeline-segment .shade{
  position: absolute;
  height:100%;  
  background-color: rgba(0,0,0,0.5);
}
