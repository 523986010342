.image-cropper{
  width: 100%;
  height: 466px;
  box-sizing: border-box;
}

.image-cropper .cropper-control{
  padding: 10px 15px 10px 15px;
  display:flex;
  justify-content: flex-end;
}

.image-cropper .cropper-area{
  background-color: #484848;
  /* background-color:black; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  box-sizing: border-box;
}


.image-cropper .cropper-area .sizer{
  width: 100%;
  height: 100%;
  padding:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border:1px solid red */
}